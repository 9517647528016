import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ToolboxPage from "./components/ToolBox";
const EducatorTools = () => {
  return (
    <div>
      <Navbar />

      <ToolboxPage />

      <Footer />
    </div>
  );
};

export default EducatorTools;
