const History = () => {
  return (
    <div className="mt-12 ">
      <div className="mt-12 ">
        {" "}
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Work History </h2>
      </div>
    </div>
  );
};

export default History;
