import React from "react";

const Report = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-screen h-screen flex justify-center items-center dark:bg-white ">
        <h1 className="relative w-full xl:text-4xl md:text-3xl text-2xl sm:tracking-[17px] tracking-[10px] uppercase text-center leading-[0.70em] outline-none animate-anim box-reflect">
          Coming Soon
        </h1>
      </div>
    </div>
  );
};

export default Report;
