import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FaqsSection from "./components/FaqDesign";
const Faqs = () => {
  return (
    <div>
      <Navbar />
      <FaqsSection />

      <Footer />
    </div>
  );
};

export default Faqs;
